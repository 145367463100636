<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="onWeekChanged">
          <div class="col-md-6">
            <div class="form-group">
              <label>Select week</label>
              <input type="week" class="form-control" v-model="selectedWeek">
            </div>
            <button class="btn btn-success btn-sm" type="submit">Submit</button>
          </div>
          </form>
        </div>
      </div>

      <div class="card" v-if="payoutSummaryLoader">
        <div class="card-body">
          <div class="text-center">
            <div class="row">
              <div class="col-md-3">
                <h3 class="lines shine"></h3>
                <p class="text-uppercase mb-1 font-13 font-weight-medium">
                  Total Drivers
                </p>
              </div>
              <div class="col-md-3">
                <p class="lines shine"></p>
                <p class="text-uppercase mb-1 font-13 font-weight-medium">
                  Total Cost
                </p>
              </div>
              <div class="col-md-3">
                <h3 class="lines shine"></h3>
                <p class="text-uppercase mb-1 font-13 font-weight-medium">
                  Total Income
                </p>
              </div>

              <div class="col-md-3">
                <h3 class="lines shine"></h3>
                <p class="text-uppercase mb-1 font-13 font-weight-medium">
                  Transfer to bank
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <template v-if="payout.length > 0">
          <div v-for="(p,index) in payout" :key="index">
            <div class="card" v-if="p['payoutSummary']">
              <div class="card-body">
                <h5 class="card-title "> Paid on {{ p['payoutSummary']['created_at'] | toFormattedDateTime }} </h5>

                <div class="text-center">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="py-1">
                        <!--                <i class="fa fa-users fa-2x text-info"></i>-->
                        <h3 class="text-info"> {{ p['payoutSummary']['total_number_drivers_for_cost'] }} </h3>
                        <p class="text-uppercase mb-1 font-13 font-weight-medium">
                          Total Drivers
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="py-1" @click="showCostDetails(index)" style="cursor:pointer">
                        <!--                <i class="fa fa-user-shield fa-2x text-warning"></i>-->
                        <h3 class="text-warning"> {{ p['payoutSummary']['total_cost'] | toCurrencyFormat }} </h3>
                        <p class="text-uppercase mb-1 font-13 font-weight-medium">
                          Total Cost
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="py-1" @click="showIncomeDetails(index)" style="cursor:pointer">
                        <h3 class="text-success"> {{ p['payoutSummary']['total_income'] | toCurrencyFormat }} </h3>
                        <p class="text-uppercase mb-1 font-13 font-weight-medium">
                          Total Income
                        </p>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="py-1" @click="showTransferToBankDetails(index)" style="cursor:pointer">
                        <h3 class="text-success"> {{ p['bankTransfer'] | toCurrencyFormat }} </h3>
                        <p class="text-uppercase mb-1 font-13 font-weight-medium">
                          Transfer to bank ({{ p['bankTransferDriversCount'] }})
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
              <div class="card" v-else>
                <div class="card-body">
                  <h4 class="card-title text-danger">There are no approved payouts for {{ selectedWeek }}</h4>
              </div>
            </div>
          </div>
        </template>
        <div class="card" v-else>
          <div class="card-body">
            <h4 class="card-title text-danger">There are no approved payouts for {{ selectedWeek }}</h4>
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-body">
          <Sidepanel @onDriverSelected="onDriverSelected" @removeDriverSelection="removeDriverDetails()"/>
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
            <!--           /////////////////////// CONTENT HERE -->
            <template v-if="driver">
              <div class="mt-0">
                <h5 class="font-18">
                  <span class="mr-3">{{ driver['name'] }} {{ driver['other_names'] }}</span>
                  <template v-if="driver['prospective_driver'] && driver['prospective_driver']['service_type']">
                    <span
                        class="badge badge-dark p-1"> {{
                        driver['prospective_driver']['service_type'] | serviceType
                      }}</span>
                  </template>
                  <template v-else>
                    (No service selected)
                  </template>

                  <span class="ml-2">|</span>
                  <span class="ml-3">Current accumulated arrears = </span>
                  <span v-if="!accumulatedArrearsLoader">{{ accumulatedArrears | toCurrencyFormat }}</span>
                  <span v-else class="lines shine col-1"></span>
                </h5>
              </div>
              <hr>

              <!--              content here-->
              <div v-if="driverPayoutLoader">
                <b-spinner small></b-spinner> loading...
              </div>
              <div v-else>
                <template v-if="numberOfPayouts > 1">

                  <div class="alert alert-info">
                    {{ numberOfPayoutDisplay }}
                  </div>

                  <hr>


                </template>

                <template v-if="payoutDetails.length > 0">

                  <template v-for="(detail, index) in payoutDetails">

                    <b-card :key="detail['id']">

                      <h4 class="card-title"> Payout made on {{ detail['dateCreated'] }} </h4>

                      <b-card>
                        <h5 class="card-title"> Cost breakdown </h5>
                        <table class="table table-striped">
                          <tr>
                            <td>Total Cost</td>
                            <td class="text-right">{{ detail['selectedWeekTotalCost'] | toCurrencyFormat }}</td>
                          </tr>
                        </table>
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th>Field name</th>
                            <th>Name displayed to driver</th>
                            <th>Field value</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="cb in detail['costBreakDown']?detail['costBreakDown']:[]">
                            <td>{{ cb['fieldName'] }}</td>
                            <td>{{ cb['fieldNameDisplay'] }}</td>
                            <td>{{ cb['fieldValue'] }}</td>
                          </tr>
                          </tbody>

                        </table>
                      </b-card>
                      <hr>
                      <b-card>
                        <h5 class="card-title"> Arrears as at payout </h5>
                        <table class="table table-striped">
                          <tr>
                            <td>As at {{ selectedWeek }}</td>
                            <template v-if="detail['arrearsAfterPayout']">
                              <td class="text-danger text-right">{{ detail['arrearsAfterPayout'] | toCurrencyFormat }}
                              </td>
                            </template>
                            <template v-else>
                              <td> N/A</td>
                            </template>

                          </tr>

                          <tr>
                            <td>As at previous week</td>
                            <template v-if="detail['arrearsBeforePayout']">
                              <td class="text-danger text-right">{{ detail['arrearsBeforePayout'] | toCurrencyFormat }}
                              </td>
                            </template>
                            <template v-else>
                              <td> N/A</td>
                            </template>

                          </tr>

                        </table>
                      </b-card>
                      <hr>
                      <b-card>
                        <h5 class="card-title"> Income breakdown </h5>
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th>Provider</th>
                            <th>Total field name</th>
                            <th>Total value</th>
                            <th>Calculated field value</th>
                          </tr>
                          </thead>
                          <tbody>
                          <template
                              v-for="payload in (detail['incomePayload'] ? JSON.parse(detail['incomePayload']) : [] )">
                            <tr>
                              <td>{{ payload['name'] }}</td>
                              <td>{{ payload['totalFieldName'] }}</td>
                              <template v-if="payload['hasTotalField']">
                                <td><span class="text-success">{{ payload['totalFieldValue'] }}</span></td>
                              </template>
                              <template v-else>
                                <td>N/A</td>
                              </template>
                              <td>
                                <template v-if="payload['hasTotalField']">
                                  <span>{{ payload['calculatedIncome'] }}</span>
                                </template>
                                <template v-else>
                                  <span class="text-success">{{ payload['calculatedIncome'] }}</span>
                                </template>

                              </td>

                            </tr>
                          </template>
                          </tbody>
                          <!--                  [{"providerId":"e232985f-2d39-45a0-bc89-c545f9cab413","name":"UBER","hasTotalField":true,"useProviderTotalField":true,"totalFieldName":"Total","totalFieldValue":"918.45","calculatedIncome":-60.7457}]-->

                        </table>
                      </b-card>
                      <b-card>
                        <h5 class="card-title"> Balance for {{ selectedWeek }} </h5>
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th>Total income for {{ selectedWeek }}</th>
                            <th>Total cost for {{ selectedWeek }}</th>
                            <th>Balance for {{ selectedWeek }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>{{ detail['selectedWeekTotalIncome'] | toCurrencyFormat }}</td>
                            <td>{{ detail['selectedWeekTotalCost'] | toCurrencyFormat }}</td>
                            <td>{{ detail['selectedWeekBalance'] | toCurrencyFormat }}</td>
                          </tr>
                          </tbody>
                          <!--                  [{"providerId":"e232985f-2d39-45a0-bc89-c545f9cab413","name":"UBER","hasTotalField":true,"useProviderTotalField":true,"totalFieldName":"Total","totalFieldValue":"918.45","calculatedIncome":-60.7457}]-->
                        </table>
                        <h5 class="card-title"> Money to be transferred to bank on {{ selectedWeek }} </h5>
                        <table class="table">
                          <tr>
                            <td>Transferred to bank</td>
                            <template v-if="detail['transferToBank'] > 0">
                              <td class="text-success">{{ detail['transferToBank'] | toCurrencyFormat }}</td>
                            </template>
                            <template v-else>
                              <td class="text-danger">{{ detail['transferToBank'] | toCurrencyFormat }}</td>
                            </template>

                          </tr>
                        </table>
                      </b-card>

                      <!--                Scheduled payments from payment plan of this week -->
                      <template v-if="detail['payoutPaymentPlans'].length > 0">
                        <hr>
                        <h5 class="card-title"> Payment schedule for this week </h5>
                        <small>This is the amount the driver has to pay for this week according to a previously scheduled
                          payment plan</small>
                        <b-card>
                          <table class="table">
                            <thead>
                            <tr>
                              <th>Amount</th>
                              <th>Week in which this plan was created</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="payoutPlan in detail['payoutPaymentPlans']">
                              <tr>
                                <td> {{ payoutPlan['amount'] }}</td>
                                <td> {{ payoutPlan['from_week'] }}</td>
                              </tr>
                            </template>
                            </tbody>

                          </table>
                        </b-card>
                      </template>

                      <template v-if="detail['createdPaymentPlan']">
                        <h5>Payment plan created for {{ selectedWeek }}</h5>
                        <small>This is the payment plan administrator created for this payout</small>
                        <b-card>
                          <table class="table">
                            <thead>
                            <tr>
                              <th>Amount to pay</th>
                              <th>Spread in weeks</th>
                              <th>Starting from</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>{{ detail['createdPaymentPlan']['total_amount_to_pay'] }}</td>
                              <td>{{ detail['createdPaymentPlan']['spread'] }}</td>
                              <td>{{ detail['createdPaymentPlan']['startingFrom'] }}</td>
                            </tr>
                            </tbody>

                          </table>
                        </b-card>
                      </template>


                    </b-card>


                  </template>

                </template>
                <h4 v-else class="card-title text-danger text-center mt-5">{{ numberOfPayoutDisplay }}</h4>
              </div>

            </template>
            <div class="text-center mt-5" v-else>
              <h4>Select a driver to view details...</h4>
            </div>
            <!--           /////////////////////// END OF CONTENT HERE -->

          </div>

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->

      <div class="row" v-if="payout">
        <div class="col-md-12">
          <b-card class="text-center">
            <button class="btn btn-success mr-2" @click="resendPayoutPushNotifications"><i
                class="fas fa-check-circle"></i> Resend notifications for week {{ selectedWeek }}
            </button>
          </b-card>
        </div>
      </div>
    </div>
    <b-modal
        id="modal-1"
        size="lg"
        v-model="showBankDetails"
        :title="'Transfer to bank details for '+selectedWeek"
        header-close-variant="light"
        title-class="font-18"
        hide-footer
    >
      <div class="text-right mb-2">
        <button class="btn btn-primary btn-sm" :disabled="downloadLoader" @click="downloadData('transfer_to_bank')">
          {{ downloadLoader ? 'Downloading...' : 'Download' }} <i class="fa fa-download"/></button>
      </div>
      <b-table
          responsive
          sticky-header
          thead-class="sticky-header"
          table-class="table table-centered w-100"
          thead-tr-class="bg-light"
          :items="transferToBankData"
          :fields="fields"
          ref="selectableTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          selectable
          @row-selected="onRowSelected"
      >
        <template v-slot:cell(checkbox)="row">
          <b-form-checkbox @click.stop v-model="row['rowSelected']"
                           @change="onCheckBoxChanged(row)"></b-form-checkbox>
        </template>
        <template v-slot:cell(driver.name)="data">
          <a target="_blank" v-if="data.item.driver"
             :href="'/prospective-driver-detail/' + data.item.driver.id">{{ data.item.driver.name }}</a>
          <div v-else>N/A</div>
        </template>

        <template v-slot:cell(transferToBank)="data">
          {{ data.item.transferToBank | toCurrencyFormat }}
        </template>

        <template v-slot:cell(accumulated_arrears)="data">
          {{ data.item.accumulated_arrears | toCurrencyFormat }}
        </template>

        <template v-slot:cell(totalIncomeForWeek)="data">
          {{ data.item.totalIncomeForWeek | toCurrencyFormat }}
        </template>

        <template v-slot:cell(totalCostForWeek)="data">
          {{ data.item.totalCostForWeek | toCurrencyFormat }}
        </template>

        <template v-slot:cell(totalBalanceForWeek)="data">
          {{ data.item.totalBalanceForWeek | toCurrencyFormat }}
        </template>


      </b-table>

    </b-modal>
    <b-modal
        id="modal-1"
        size="lg"
        v-model="showIncomeModal"
        :title="title"
        header-close-variant="light"
        title-class="font-18"
        hide-footer
    >
      <div class="text-right mb-2">
        <button class="btn btn-primary btn-sm" :disabled="downloadLoader" @click="downloadData('income')">
          {{ downloadLoader ? 'Downloading...' : 'Download' }} <i class="fa fa-download"/></button>
      </div>
      <b-table
          responsive
          sticky-header
          thead-class="sticky-header"
          table-class="table table-centered w-100"
          thead-tr-class="bg-light"
          :items="incomeDetails"
          :fields="incomeFields"
          ref="selectableTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          selectable
          @row-selected="onRowSelected"
      >
        <template v-slot:cell(checkbox)="row">
          <b-form-checkbox @click.stop v-model="row['rowSelected']"
                           @change="onCheckBoxChanged(row)"></b-form-checkbox>
        </template>
        <template v-slot:cell(driver_user.name)="data">
          <a target="_blank" v-if="data.item.driver_user"
             :href="'/prospective-driver-detail/' + data.item.driver_user.id">{{ data.item.driver_user.name }}</a>
          <div v-else>N/A</div>
        </template>

        <template v-slot:cell(driver_total_income)="data">
          {{ data.item.driver_total_income | toCurrencyFormat }}
        </template>

        <template v-slot:cell(status)="data">
          {{ data.item.status | toCurrencyFormat }}
        </template>

      </b-table>

    </b-modal>

    <b-modal
        id="modal-1"
        size="lg"
        v-model="showCostModal"
        :title="title"
        header-close-variant="light"
        title-class="font-18"
        hide-footer
    >
      <div class="text-right mb-2">
        <button class="btn btn-primary btn-sm" :disabled="downloadLoader" @click="downloadData('cost')">
          {{ downloadLoader ? 'Downloading...' : 'Download' }} <i class="fa fa-download"/></button>
      </div>
      <b-table
          responsive
          sticky-header
          thead-class="sticky-header"
          table-class="table table-centered w-100"
          thead-tr-class="bg-light"
          :items="costDetails"
          :fields="costFields"
          ref="selectableTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          selectable
          @row-selected="onRowSelected"
      >
        <template v-slot:cell(checkbox)="row">
          <b-form-checkbox @click.stop v-model="row['rowSelected']"
                           @change="onCheckBoxChanged(row)"></b-form-checkbox>
        </template>
        <template v-slot:cell(driver_user.name)="data">
          <a target="_blank" v-if="data.item.driver_user"
             :href="'/prospective-driver-detail/' + data.item.driver_user.id">{{ data.item.driver_user.name }}</a>
          <div v-else>N/A</div>
        </template>

        <template v-slot:cell(driver_total_income)="data">
          {{ data.item.driver_total_cost | toCurrencyFormat }}
        </template>

        <template v-slot:cell(status)="data">
          {{ data.item.status | toCurrencyFormat }}
        </template>

      </b-table>

    </b-modal>

    <b-modal
        id="send-notification-modal"
        size="md"
        v-model="notificationModal"
        :title="'Resend notifications for week '+ selectedWeek"
        header-close-variant="light"
        title-class="font-18"
        hide-footer
    >

      <form @submit.prevent="sendNotification">
        <label for="">Select payout period</label>
        <select class="form-control" v-model="selectedPayoutForNotification" required>
          <option v-for="(p,i) in payout" :key="i" :value="p['payoutSummary']">
            {{ p['payoutSummary']['created_at'] | toFormattedDateTime }}
          </option>
        </select>
        <div class="text-center mt-3">
          <button class="btn btn-success" type="submit">Send Notification</button>
        </div>
      </form>

    </b-modal>



  </div>
</template>

<script>
import {payoutService} from "../../../../apis/payout.service";
import Sidepanel from "./sidepanel";
import {Skeleton} from "vue-loading-skeleton";
import moment from "moment";
import {confirm} from "../../../../utils/functions";

export default {
  name: "make-payout",
  components: {
    Sidepanel,
    Skeleton
  },
  data() {
    return {
      title: '',
      selectedPayoutForNotification: '',
      driver: null,
      selectedWeek: '',
      notificationModal: false,
      showBankDetails: false,
      showIncomeModal: false,
      showCostModal: false,
      transferToBankData: [],
      incomeDetails: [],
      costDetails: [],
      fields: [
        {
          label: "Driver name",
          key: "driver.name",
          sortable: true,
        },
        {
          label: "Dossier",
          key: "driver.driver.dossier",
          sortable: true,
        },
        {
          label: "Transfer To Bank",
          key: "transferToBank",
          sortable: true,
        },
        {
          label: "Accumulated Arrears",
          key: "accumulated_arrears",
          sortable: true,
        },
        {
          label: "Total Income For Week",
          key: "totalIncomeForWeek",
          sortable: true,
        },
        {
          label: "Total Cost For Week",
          key: "totalCostForWeek",
          sortable: true,
        },
        {
          label: "Total Balance For Week",
          key: "totalBalanceForWeek",
          sortable: true,
        },
      ],
      incomeFields: [
        {
          label: "Driver name",
          key: "driver_user.name",
          sortable: true,
        },
        {
          label: "Dossier",
          key: "driver_user.driver.dossier",
          sortable: false,
        },
        {
          label: "Total Income",
          key: "driver_total_income",
          sortable: true,
        },
        {
          label: "status",
          key: "status",
          sortable: false,
        }
      ],
      costFields: [
        {
          label: "Driver name",
          key: "driver_user.name",
          sortable: true,
        },
        {
          label: "Dossier",
          key: "driver_user.driver.dossier",
          sortable: false,
        },
        {
          label: "Total Cost",
          key: "driver_total_cost",
          sortable: true,
        },
        {
          label: "Status",
          key: "status",
          sortable: false,
        }
      ],
      sortBy: "driver.name",
      sortDesc: true,
      filter: null,
      filterOn: [],
      data: null,
      selectedIncomeCostType: '',
      accumulatedArrears: '0.00',
      paymentPlanInWeeksForm: {
        spread: ''
      },
      payout: [],
      paymentPlanInWeeks: null,
      payoutDetails: [],
      numberOfPayouts: 0,
      numberOfPayoutDisplay: '',
      totalBankTransfer: 0.00,
      payoutSummaryLoader: false,
      downloadLoader: false,
      accumulatedArrearsLoader: false,
      driverPayoutLoader: false,
    }
  },
  methods: {
    downloadData(type) {
      let payload = this.getPayload(type);
      this.downloadDataBackend(payload);
    },

    downloadDataBackend(payload) {
      this.downloadLoader = true;
      payoutService.downloadData(payload).then((data) => {
        this.downloadLoader = false;

        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }
        location.href = `${process.env.VUE_APP_API_BASE_URL}/get-download-data?file=${data['extra']}`;
      })
    },

    getPayload(type) {
      let header = [];
      let data = [];
      let file_name = '';

      if (type === 'cost') {
        file_name = `cost_details_for_${this.selectedWeek}.xlsx`;
        header = this.costFields.map((field) => field['label']);
        data = this.costDetails.map((detail) => {
          return {
            name: detail['driver_user']['name'],
            dossier: detail['driver_user']['driver']['dossier'],
            total_cost: detail['driver_total_cost'],
            status: detail['status'],
          }
        });
      }

      if (type === 'income') {
        file_name = `income_details_for_${this.selectedWeek}.xlsx`;
        header = this.incomeFields.map((field) => field['label']);
        data = this.incomeDetails.map((detail) => {
          return {
            name: detail['driver_user']['name'],
            dossier: detail['driver_user']['driver']['dossier'],
            total_cost: detail['driver_total_income'],
            status: detail['status'],
          }
        });
      }

      if (type === 'transfer_to_bank') {
        file_name = `transfer_to_bank_details_for_${this.selectedWeek}.xlsx`;
        header = this.fields.map((field) => field['label']);
        data = this.transferToBankData.map((detail) => {
          return {
            name: detail['driver']['name'],
            dossier: detail['driver']['driver']['dossier'],
            transferToBank: detail['transferToBank'],
            accumulated_arrears: detail['accumulated_arrears'],
            totalIncomeForWeek: detail['totalIncomeForWeek'],
            totalCostForWeek: detail['totalCostForWeek'],
            totalBalanceForWeek: detail['totalBalanceForWeek']
          }
        });
      }
      return {
        file_name,
        header,
        data
      };
    },
    showTransferToBankDetails(payoutIndex) {
      this.transferToBankData = this.payout[payoutIndex]['bankTransferDetails'];
      this.showBankDetails = true;
      this.title = 'Transfer to bank details for ' + this.selectedWeek;
    },

    showIncomeDetails(payoutIndex) {
      this.incomeDetails = this.payout[payoutIndex]['payoutSummary']['payout_details'];
      this.showIncomeModal = true;
      let totalIncome = this.incomeDetails.reduce((a, b) => a + (Number(b['driver_total_income']) || 0), 0);
      totalIncome = totalIncome ? process.env.VUE_APP_CURRENCY + " " + (Math.round(totalIncome * 100) / 100).toFixed(2) : totalIncome;
      this.title = 'Income details for ' + this.selectedWeek + ' (' + totalIncome + ' )';
    },

    showCostDetails(payoutIndex) {
      this.costDetails = this.payout[payoutIndex]['payoutSummary']['payout_details'];
      this.showCostModal = true;
      let totalCost = this.costDetails.reduce((a, b) => a + (Number(b['driver_total_cost']) || 0), 0);
      totalCost = totalCost ? process.env.VUE_APP_CURRENCY + " " + (Math.round(totalCost * 100) / 100).toFixed(2) : totalCost;
      this.title = 'Cost details for ' + this.selectedWeek + ' (' + totalCost + ' )';
    },
    onDriverSelected(driver) {
      this.driver = driver;
    },

    removeDriverDetails() {
      this.driver = '';
    },

    onRowSelected(selectedRows) {
      this.selectedItems = selectedRows;
    },

    onCheckBoxChanged(row) {
      const index = row['index']
      if (this.$refs.selectableTable.isRowSelected(index)) {
        this.$refs.selectableTable.unselectRow(index)
      } else {
        this.$refs.selectableTable.selectRow(index)
      }
    },

    getPreviousWeekInHtmlFormat() {
      const year = moment().year();
      const week = moment().week();
      return year + '-W' + week.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
    },

    getPayoutSummary() {
      if (this.selectedWeek === '') {
        return;
      }
      const payload = {
        'week': this.selectedWeek,
        'status': 'approved'
      }

      this.payoutSummaryLoader = true;
      payoutService.getPayoutSummary(payload).then((data) => {
        this.payoutSummaryLoader = false;

        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }

        this.payout = data['extra']['payouts'];

      })
    },

    getGeneratedPayout() {
      if (!this.driver) {
        return;
      }
      if (this.selectedWeek === '') {
        return;
      }

      const payload = {
        'week': this.selectedWeek,
        'driver_user_id': this.driver['id'],
        'status': 'approved'
      }

      this.driverPayoutLoader = true;
      payoutService.getPayoutHistory(payload).then((data) => {
        this.driverPayoutLoader = false;

        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }

        this.payoutDetails = data['extra']['payoutDetails'];
        this.numberOfPayoutDisplay = data['extra']['numberOfPayoutDisplay']
        this.numberOfPayouts = data['extra']['numberOfPayouts']
        this.totalBankTransfer = data['extra']['totalBankTransfer']

      })

    },


    submitCreatePaymentPlan(payoutIndex) {

      if (this.payout.length < 1) {
        return;
      }

      confirm('Create payment plan', () => {
        const payload = {
          'payoutDetailId': this.payout[payoutIndex]['payoutDetail']['id'],
          'spread': this.paymentPlanInWeeksForm.spread,
        }

        this.$store.dispatch('showLoader')
        payoutService.createPaymentPlan(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if (!data.status) {
            this.$store.dispatch('error', {message: data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: data.message, showSwal: true})

        })
      })


    },

    sendNotification(){
      confirm('Send out notifications', () => {
        this.notificationModal = false;
        this.$store.dispatch('showLoader')
        payoutService.resendGeneratePayoutNotifications(this.selectedPayoutForNotification.id).then((data) => {
          this.$store.dispatch('hideLoader')

          if (!data.status) {
            this.$store.dispatch('error', {message: data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: data.message, showSwal: true})

        })
      })

    },

    resendPayoutPushNotifications() {
      if (this.payout.length <1) {
        alert('There are no payouts for this week');
        return;
      }

      this.notificationModal =true;


    },


    getArrearsForDriver() {
      if (!this.driver) {
        return;
      }

      this.accumulatedArrearsLoader = true;
      payoutService.fetchArrears(this.driver['id']).then((data) => {
        this.accumulatedArrearsLoader = false;


        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }

        this.accumulatedArrears = data['extra']['accumulated_amount'];

      })

    },

    onWeekChanged() {
      if (!this.selectedWeek) {
        alert('Select a week..');
        return;
      }
      this.getPayoutSummary();
      this.getGeneratedPayout()
    },

    deleteGeneratedPayout(payoutId) {

      confirm('Delete payout for ' + this.selectedWeek, () => {
        this.$store.dispatch('showLoader')
        payoutService.deleteGeneratePayout(payoutId).then((data) => {
          this.$store.dispatch('hideLoader')

          if (!data.status) {
            this.$store.dispatch('error', {message: data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: data.message, showSwal: true})
          this.getPayoutSummary();
          this.getGeneratedPayout()

        })
      })

    },


  },
  watch: {
    driver: function (driver) {
      if (driver) {
        this.getArrearsForDriver();
        this.getGeneratedPayout();
      }
    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Payout history');
    this.selectedWeek = this.getPreviousWeekInHtmlFormat();
    this.getPayoutSummary()
    this.getGeneratedPayout()
  }
}
</script>

<style scoped>
> > thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
}
</style>